/* eslint-disable import/no-cycle */
import api from './index';

const prefix = 'driver';
const getDriverList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );
const getDriverDetails = (id) => api.get(`${prefix}/${id}`);
const postDriver = (data) => api.post(prefix, data);
const putDriver = (id, data) => api.put(`${prefix}/${id}`, data);
const deleteDriver = (id) => api.delete(`${prefix}/${id}`);
const postDriverUser = (id, data) => api.post(`${prefix}/${id}/user`, data);
const getDriverStatus = (query) =>
  api.get(
    `${prefix}/status?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );

export {
  getDriverList,
  getDriverDetails,
  postDriver,
  putDriver,
  deleteDriver,
  postDriverUser,
  getDriverStatus,
};
