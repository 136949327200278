/* eslint-disable import/no-cycle */
import api from './index';

const prefix = 'routing';
const getRoutingList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );
const getRoutingDetails = (id) => api.get(`${prefix}/${id}`);
const postRouting = (data) => api.post(prefix, data);
const putRouting = (id, data) => api.put(`${prefix}/${id}`, data);
const putRoutingBatch = (data) => api.put(`${prefix}/batch`, data);
const deleteRouting = (id) => api.delete(`${prefix}/${id}`);
const postRoutingDiscardBatch = (data) => api.post(`${prefix}/discard-batch`, data)
const putRoutingStatus = (id, data) => api.put(`${prefix}/${id}/status`, data);
const putRoutingStatusBatch = (data) => api.put(`${prefix}/status-batch`, data);
const getRoutingWhatsapp = (id) => api.get(`${prefix}/${id}/whatsapp`);
const getRoutingMap = (id) => api.get(`${prefix}/${id}/map`);
const postRoutingExport = (data) => api.post(`${prefix}/export`, data);
const postRoutingSendEmail = (id) => api.post(`${prefix}/${id}/send-email`, {});
const getRoutingSolicitations = (query) =>
  api.get(
    `${prefix}/solicitation?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );
const postRoutingSolicitations = (data) =>
  api.post(`${prefix}/solicitation`, data);

const postRoutingNotifyDriver = (id) =>
  api.post(`${prefix}/${id}/notify-driver`, {});

const putRoutingSolicitationsPosition = (id, data) =>
  api.put(`${prefix}/${id}/solicitations-position`, data);

const getRoutingKanban = (query) =>
  api.get(
    `${prefix}/kanban?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );

const putRoutingDriver = (id, data) => api.put(`${prefix}/${id}/driver`, data);

const getRoutingHistory = (id, query) =>
  api.get(
    `${prefix}/${id}/history?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );

export {
  getRoutingList,
  getRoutingDetails,
  postRouting,
  putRouting,
  putRoutingBatch,
  deleteRouting,
  postRoutingDiscardBatch,
  putRoutingStatus,
  putRoutingStatusBatch,
  getRoutingWhatsapp,
  getRoutingMap,
  postRoutingExport,
  getRoutingSolicitations,
  postRoutingSolicitations,
  postRoutingSendEmail,
  postRoutingNotifyDriver,
  putRoutingSolicitationsPosition,
  getRoutingKanban,
  putRoutingDriver,
  getRoutingHistory,
};
