import {
  getRoutingList,
  getRoutingDetails,
  putRouting,
  postRouting,
  deleteRouting,
  putRoutingBatch,
  putRoutingStatus,
  putRoutingStatusBatch,
  getRoutingWhatsapp,
  getRoutingMap,
  postRoutingExport,
  getRoutingSolicitations,
  postRoutingSolicitations,
  postRoutingSendEmail,
  postRoutingNotifyDriver,
} from '../api';

const list = (params) => getRoutingList(params);
const show = (id) => getRoutingDetails(id);
const update = (id, data) => putRouting(id, data);
const store = (data) => postRouting(data);
const destroy = (id) => deleteRouting(id);
const updateBatch = (data) => putRoutingBatch(data);
const updateStatus = (id, data) => putRoutingStatus(id, data);
const updateStatusBatch = (data) => putRoutingStatusBatch(data);
const whatsapp = (id) => getRoutingWhatsapp(id);
const map = (id) => getRoutingMap(id);
const exportRoutings = (data) => postRoutingExport(data);
const solicitations = (params) => getRoutingSolicitations(params);
const generateSolicitations = (params) => postRoutingSolicitations(params);
const sendByEmail = (id) => postRoutingSendEmail(id);
const notifyDriver = (id) => postRoutingNotifyDriver(id);

export const routingService = {
  list,
  show,
  update,
  store,
  destroy,
  updateBatch,
  updateStatus,
  updateStatusBatch,
  whatsapp,
  map,
  sendByEmail,
  exportRoutings,
  solicitations,
  generateSolicitations,
  notifyDriver,
};
