/* eslint-disable import/no-cycle */
import api from './index';

const prefix = 'address';
const getAddressList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );
const getAddressDetails = (id) => api.get(`${prefix}/${id}`);
const postAddress = (data) => api.post(prefix, data);
const putAddress = (id, data) => api.put(`${prefix}/${id}`, data);
const deleteAddress = (id) => api.delete(`${prefix}/${id}`);
const getAddressFromLatLng = (lat, lng) =>
  api.get(`${prefix}/lat-lng?lat=${lat}&lng=${lng}`);

export {
  getAddressList,
  getAddressDetails,
  postAddress,
  putAddress,
  deleteAddress,
  getAddressFromLatLng,
};
// eslint-disable-next-line prettier/prettier
