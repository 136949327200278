/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import api from './index';

const prefix = 'dashboard';
const getDashboard = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );

export { getDashboard };
