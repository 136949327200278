import {
  getUserList,
  getUserDetails,
  putUser,
  postUser,
  deleteUser,
  postUserPhoto,
  getRoleList,
} from '../api';

const list = (params) => getUserList(params);
const show = (id) => getUserDetails(id);
const update = (id, data) => putUser(id, data);
const store = (data) => postUser(data);
const destroy = (id) => deleteUser(id);
const updatePhoto = (id, data) => postUserPhoto(id, data);
const listRoles = (params) => getRoleList(params);

export const userService = {
  list,
  show,
  update,
  store,
  destroy,
  updatePhoto,
  listRoles,
};
