/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-sub-hidden';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale =
  window.location.hostname !== 'app.aexecutivataxi.com.br' ? 'br' : 'ez';
export const localeOptions = [
  { id: 'br', name: 'Português', direction: 'ltr' },
  { id: 'ez', name: 'Executiva', direction: 'ltr' },
];

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = false;
export const defaultColor =
  window.location.hostname !== 'app.aexecutivataxi.com.br'
    ? 'light.jv'
    : 'light.executiva';
export const defaultDirection = 'ltr';
export const isDarkSwitchActive = false;
export const themeColorStorageKey = '__theme_color';
export const themeRadiusStorageKey = '__theme_radius';
export const isDemo = false;

export const defaultPageSize = 15;
