import React, { useState } from 'react';
import {
  Collapse,
  Alert,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  CustomInput,
} from 'reactstrap';
import RoutingPassenger from './RoutingPassenger';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { routingService } from '../../services';
import { NotificationManager } from '../common/react-notifications';
import { NavLink } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';
import { connect } from 'react-redux';
import { toggleRouteSelected, updateSolicitation } from '../../redux/actions';
import RoutingWaypoint from './RoutingWaypoint';

export function checkCanNext(route) {
  const { vehicle, driver, solicitations } = route;
  let canNext = false;
  if (route.status === 'PLANNED') {
    canNext = solicitations.length > 0 && vehicle && driver;
  } else if (route.status === 'PENDING_ACCEPT') {
    canNext = true;
  } else if (route.status === 'ACCEPTED' || route.status === 'RUNNING') {
    canNext = true;
  }

  return canNext;
}

export async function getRouteWpp(route) {
  try {
    const { data } = await routingService.whatsapp(route.id);

    const el = document.createElement('textarea');
    el.value = data.text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    NotificationManager.success(
      'Copiado para área de transferência',
      'Copiado!',
      1000,
    );
  } catch (err) {
    NotificationManager.error(
      err.message,
      'Erro ao buscar dados p/ whatsapp',
      3000,
      null,
      null,
      '',
    );
  }
}

export async function sendByEmail(route) {
  try {
    await routingService.sendByEmail(route.id);
    NotificationManager.success(
      'Um e-mail foi enviado para os solicitantes',
      'Enviado!',
      1000,
    );
  } catch (err) {
    NotificationManager.error(
      err.message,
      'Erro ao enviar email',
      3000,
      null,
      null,
      '',
    );
  }
}

export async function getRouteMap(route) {
  try {
    const { data } = await routingService.map(route.id);
    if (data.link) {
      window.open(data.link, '_blank');
    }
  } catch (err) {
    NotificationManager.error(
      err.message,
      'Erro ao buscar dados p/ mapa',
      3000,
      null,
      null,
      '',
    );
  }
}

export async function notifyDriver(route) {
  try {
    await routingService.notifyDriver(route.id);
    NotificationManager.success('Motorista notificado', 'Pronto!', 1000);
  } catch (err) {
    NotificationManager.error(
      err.message,
      'Não foi possível notificar o motorista',
      3000,
      null,
      null,
      '',
    );
  }
}

function RoutingRoute({
  route,
  toggleRouteSelected,
  onDelete,
  onGetData,
  onSendDriver,
  onConfirmDriver,
  onSendToRequester,
  onFinishRoute,
  canActions,
  history,
  routing,
  updateSolicitation,
  onOpenUpdateModal,
}) {
  const { vehicle, driver } = route;

  const solicitations = routing.solicitations
    .filter((x) => x.routing_id === route.id)
    .sort((a, b) => {
      return a.position - b.position;
    });

  const [collapse, setCollapse] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: 'ROUTE',
    item: route,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => {
      return vehicle !== null && driver !== null;
    },
  }));

  const [{ isOver }, dropRef] = useDrop(() => ({
    accept: 'SOLICITATION',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: (item, monitor) => {
      if (item.routing_id !== route.id) {
        item.routing_id = route.id;
        item.isModified = true;
        updateSolicitation(item);
        setCollapse(true);
      }
    },
    canDrop: () => {
      return route.status !== 'RUNNING' && !route.is_charter;
    },
  }));

  const onChangePosition = (item, position) => {
    item.position = position;
    item.isModified = true;

    updateSolicitation(item);
  };

  const defaultImg = '/assets/img/avatar.jpg';

  const canNext = checkCanNext(route);

  var border = '1px solid #ddd';

  if (solicitations.length > (vehicle ? vehicle.capacity : 0)) {
    border = '1px solid #ffc107';
  }

  if (solicitations.some((s) => s.status === 'CANCELED')) {
    border = '1px solid #dc3545';
  }

  var bgColor = '#f1f1f1';

  if (route.is_charter) {
    bgColor = '#d2d7e7';
  }

  if (solicitations.some((s) => s.isModified)) bgColor = '#fbeec7';
  if (isOver) bgColor = '#ffcc00';
  if (isDragging) bgColor = '#fa5560';

  return (
    <div ref={dragRef}>
      <div
        ref={dropRef}
        className="rounded-simple shadow-sm p-1 mt-2"
        style={{ border: border, backgroundColor: bgColor }}
      >
        <div style={{ fontSize: '11px' }}>
          <div
            className="clearfix mb-1 pt-1"
            style={{ borderBottom: '1px dotted #ccc' }}
          >
            <CustomInput
              type="checkbox"
              id={`selectedRoute${route.id}`}
              className="mr-0 float-left"
              checked={route.isSelected ?? false}
              onClick={() => toggleRouteSelected(route.id)}
              disabled={!canNext}
              inline
            />
            <span style={{ fontSize: '14px' }}>#{route.id}</span>
            {canActions && (
              <span className="float-right ml-1">
                <Dropdown
                  isOpen={dropdown}
                  toggle={() => setDropdown(!dropdown)}
                  className="p-0 mb-1"
                >
                  <DropdownToggle
                    caret={false}
                    color="transparent"
                    style={{ fontSize: '10px', padding: '0 5px' }}
                  >
                    <span className="simple-icon-options-vertical" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <NavLink to={`/app/ope/routing/${route.id}`}>
                      <DropdownItem>Detalhes da corrida</DropdownItem>
                    </NavLink>
                    {solicitations.length > 0 && (
                      <DropdownItem
                        onClick={async () => {
                          setLoading(true);
                          await getRouteMap(route);
                          setLoading(false);
                        }}
                      >
                        Ver no Google Maps
                      </DropdownItem>
                    )}
                    {route.status === 'PLANNED' && canNext && (
                      <DropdownItem
                        className="text-info"
                        onClick={() => onSendDriver(route)}
                      >
                        Enviar p/ Motorista{' '}
                        <i className="simple-icon-action-redo" />
                      </DropdownItem>
                    )}
                    {route.status === 'PENDING_ACCEPT' && (
                      <>
                        <DropdownItem
                          onClick={async () => {
                            setLoading(true);
                            await notifyDriver(route);
                            setLoading(false);
                          }}
                        >
                          Notificar Motorista (APP)
                        </DropdownItem>
                        <DropdownItem
                          onClick={async () => {
                            setLoading(true);
                            await getRouteWpp(route);
                            setLoading(false);
                          }}
                        >
                          Copiar p/ Whatsapp
                        </DropdownItem>
                        <DropdownItem
                          className="text-info"
                          onClick={() => onConfirmDriver(route)}
                        >
                          Confirmar Motorista{' '}
                          <i className="simple-icon-action-redo" />
                        </DropdownItem>
                      </>
                    )}
                    {route.status === 'ACCEPTED' && (
                      <>
                        <DropdownItem
                          onClick={async () => {
                            setLoading(true);
                            await sendByEmail(route);
                            setLoading(false);
                          }}
                        >
                          Enviar dados p/ solicitante
                        </DropdownItem>
                      </>
                    )}
                    {[
                      'PLANNED',
                      'PENDING_ACCEPT',
                      'ACCEPTED',
                      'RUNNING',
                    ].includes(route.status) && (
                      <>
                        <DropdownItem divider />
                        <DropdownItem onClick={() => onOpenUpdateModal(route)}>
                          Editar
                        </DropdownItem>
                        <DropdownItem
                          className="text-danger"
                          onClick={() => onDelete(route)}
                        >
                          Descartar
                        </DropdownItem>
                      </>
                    )}
                    {(route.status === 'RUNNING' ||
                      route.status === 'ACCEPTED') && (
                      <>
                        <DropdownItem
                          className="text-success"
                          onClick={() => onFinishRoute(route)}
                        >
                          Finalizar Corrida{' '}
                          <i className="simple-icon-action-redo" />
                        </DropdownItem>
                      </>
                    )}
                  </DropdownMenu>
                </Dropdown>
              </span>
            )}
            <span className="float-right">
              ({solicitations.length}/{vehicle ? vehicle.capacity || '-' : '-'})
            </span>
          </div>

          <div
            style={{
              fontSize: '12px',
              display: 'flex',
              justifyContent: 'center',
            }}
            onClick={() => setCollapse(!collapse)}
          >
            <div style={{ flexGrow: 1 }}>
              <div style={{ borderBottom: '1px dashed #ccc' }}>
                {vehicle && vehicle.photo ? (
                  <img
                    src={vehicle}
                    style={{
                      height: '15px',
                      width: '15px',
                      objectFit: 'cover',
                    }}
                    alt="veículo"
                    className="rounded-simple"
                  />
                ) : (
                  <i className="simple-icon-cursor" />
                )}{' '}
                {vehicle ? `${vehicle.model} [${vehicle.plate}]` : '-'}
              </div>
              <div
                style={{
                  borderBottom: '1px dashed #ccc',
                  paddingBottom: '2px',
                }}
              >
                <img
                  src={`${
                    driver ? driver.person.photo || defaultImg : defaultImg
                  }`}
                  style={{
                    height: '17px',
                    width: '17px',
                    objectFit: 'cover',
                  }}
                  alt="motorista"
                  className="rounded-simple"
                />{' '}
                {driver
                  ? driver.person.name.split(' ').slice(0, 2).join(' ')
                  : '-'}
              </div>
            </div>
            <div
              style={{
                width: '53px',
                padding: '3px',
                textAlign: 'center',
                border: '1px dashed #ccc',
                background: '#f7f7f7',
                borderRadius: '7px',
              }}
            >
              {/* <i className="simple-icon-calendar" />{" "} */}
              <strong>
                {moment(route.loading_at).format('DD/MM')}
                <br />
                {moment(route.loading_at).format('HH:mm')}
              </strong>
            </div>
          </div>

          {loading && (
            <div className="text-center">
              <Spinner size="sm" color="primary" />
            </div>
          )}
        </div>

        <div className="p-0">
          <Collapse isOpen={collapse}>
            {route.is_charter ? (
              <>
                {route.waypoints.map((w, index) => (
                  <RoutingWaypoint key={w.id} waypoint={w} />
                ))}
                {route.waypoints.length === 0 && (
                  <Alert color="dark" className="p-1 pt-3 pb-3 mt-2 mb-0">
                    Nenhum ponto cadastrado
                  </Alert>
                )}
              </>
            ) : (
              <>
                {solicitations.map((p, index) => (
                  <RoutingPassenger
                    key={p.id}
                    passenger={p}
                    index={index}
                    onGetData={onGetData}
                    onChangePosition={onChangePosition}
                    lastIndex={solicitations.length}
                  />
                ))}
                {solicitations.length === 0 && (
                  <Alert color="dark" className="p-1 pt-3 pb-3 mt-2 mb-0">
                    Nenhum passageiro
                  </Alert>
                )}
              </>
            )}
          </Collapse>
        </div>
        <div
          className="mt-2 default btn btn-xs btn-light btn-block"
          style={{ padding: 0 }}
          onClick={() => setCollapse(!collapse)}
        >
          <small>
            {route.is_charter ? 'Pontos ' : 'Passageiros '}
            <i
              className={`${
                collapse ? 'simple-icon-arrow-up' : 'simple-icon-arrow-down'
              }`}
            />
          </small>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ routing }) => {
  return {
    routing,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    updateSolicitation,
    toggleRouteSelected,
  })(RoutingRoute),
);
