/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import api from './index';

const prefix = 'profile';
const getProfileAdresses = (query) =>
  api.get(
    `${prefix}/address?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );

export { getProfileAdresses };
