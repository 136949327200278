/* eslint-disable import/no-cycle */
import api from './index';

const prefix = 'contract';
const getContractList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );
const getContractDetails = (id) => api.get(`${prefix}/${id}`);
const postContract = (data) => api.post(prefix, data);
const putContract = (id, data) => api.put(`${prefix}/${id}`, data);
const deleteContract = (id) => api.delete(`${prefix}/${id}`);

export {
  getContractList,
  getContractDetails,
  postContract,
  putContract,
  deleteContract,
};
