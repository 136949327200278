/* eslint-disable import/no-cycle */
import api from './index';

const prefix = 'cost-center';
const getCostCenterList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );
const getCostCenterDetails = (id) => api.get(`${prefix}/${id}`);
const postCostCenter = (data) => api.post(prefix, data);
const putCostCenter = (id, data) => api.put(`${prefix}/${id}`, data);
const deleteCostCenter = (id) => api.delete(`${prefix}/${id}`);

export {
  getCostCenterList,
  getCostCenterDetails,
  postCostCenter,
  putCostCenter,
  deleteCostCenter,
};
