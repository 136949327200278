import {
  getAddressList,
  getAddressDetails,
  putAddress,
  postAddress,
  deleteAddress,
  getSolicitationAddress,
} from '../api';

const list = (params) => getAddressList(params);
const show = (id) => getAddressDetails(id);
const update = (id, data) => putAddress(id, data);
const store = (data) => postAddress(data);
const destroy = (id) => deleteAddress(id);
const address = (params) => getSolicitationAddress(params);

export const addressService = {
  list,
  show,
  update,
  store,
  destroy,
  address,
};
