import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-table/react-table.css';
import Echo from 'laravel-echo';

import {
  isMultiColorActive,
  defaultColor,
  themeColorStorageKey,
  isDarkSwitchActive,
} from './constants/defaultValues';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.REACT_APP_PUSHER_KEY,
  cluster: 'mt1',
  wsHost: process.env.REACT_APP_PUSHER_HOST,
  wsPort: 6001,
  wssPort: 6001,
  forceTLS: process.env.REACT_APP_PUSHER_FORCETLS === 'true',
  encrypted: process.env.REACT_APP_PUSHER_ENCRYPTED === 'true',
  enabledTransports: ['ws', 'wss'],
  disableStats: false,
});

const color =
  (isMultiColorActive || isDarkSwitchActive) &&
  localStorage.getItem(themeColorStorageKey)
    ? localStorage.getItem(themeColorStorageKey)
    : defaultColor;

localStorage.setItem(themeColorStorageKey, color);

const render = () => {
  import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
    require('./AppRenderer'); // eslint-disable-line global-require
  });
};
render();
