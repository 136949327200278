import {
  getContractList,
  getContractDetails,
  putContract,
  postContract,
  deleteContract,
} from '../api';

const list = (params) => getContractList(params);
const show = (id) => getContractDetails(id);
const update = (id, data) => putContract(id, data);
const store = (data) => postContract(data);
const destroy = (id) => deleteContract(id);

export const contractService = {
  list,
  show,
  update,
  store,
  destroy,
};
