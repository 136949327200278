import React from 'react';
import { Badge } from 'reactstrap';
import IntlMessages from '../../helpers/IntlMessages';
import { getColorForStatus } from '../../helpers/Utils';

function Status({ value, className }) {
  const color = getColorForStatus(value, false);

  return (
    <Badge color={color} className={className}>
      <IntlMessages id={`status.${value}`} />
    </Badge>
  );
}

export default Status;
