import {
  getVehicleList,
  getVehicleDetails,
  putVehicle,
  postVehicle,
  deleteVehicle,
  postVehiclePhoto,
} from '../api';

const list = (params) => getVehicleList(params);
const show = (id) => getVehicleDetails(id);
const update = (id, data) => putVehicle(id, data);
const store = (data) => postVehicle(data);
const destroy = (id) => deleteVehicle(id);
const updatePhoto = (id, data) => postVehiclePhoto(id, data);

export const vehicleService = {
  list,
  show,
  update,
  store,
  destroy,
  updatePhoto,
};
