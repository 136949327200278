/* eslint-disable import/no-cycle */
/* eslint-disable prettier/prettier */
import api from './index';

const prefix = 'service-order';
const getServiceOrderList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );
const getServiceOrderDetails = (id) => api.get(`${prefix}/${id}`);
const postServiceOrder = (data) => api.post(prefix, data);
const putServiceOrder = (id, data) => api.put(`${prefix}/${id}`, data);
const deleteServiceOrder = (id, reason) =>
  api.delete(`${prefix}/${id}`, { reason });
const getServiceOrderDash = () => api.get(`${prefix}/dash`);
const postServiceOrderBatch = (data) => api.post(`${prefix}/batch`, data);

export {
  getServiceOrderList,
  getServiceOrderDetails,
  postServiceOrder,
  putServiceOrder,
  deleteServiceOrder,
  getServiceOrderDash,
  postServiceOrderBatch,
};
