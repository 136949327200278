/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const CHANGE_PAGE_SIZE = 'CHANGE_PAGE_SIZE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* ROUTINGS */
export const SET_ROUTING = 'SET_ROUTING';
export const UPDATE_SOLICITATION = 'UPDATE_SOLICITATION';
export const TOGGLE_ROUTE_SELECTED = 'TOGGLE_ROUTE_SELECTED';
export const SELECT_ALL_ROUTES = 'SELECT_ALL_ROUTES';
export const UPDATE_ROUTE = 'UPDATE_ROUTE';
export const UPDATE_WAYPOINT = 'UPDATE_WAYPOINT';
export const FINISH_ROUTE = 'FINISH_ROUTE';
export const INSERT_ROUTE = 'INSERT_ROUTE';
export const INSERT_MULTI_ROUTES = 'INSERT_MULTI_ROUTES';

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';
export * from './routing/actions';
