import {
  SET_ROUTING,
  UPDATE_SOLICITATION,
  TOGGLE_ROUTE_SELECTED,
  SELECT_ALL_ROUTES,
  UPDATE_ROUTE,
  UPDATE_WAYPOINT,
  FINISH_ROUTE,
  INSERT_ROUTE,
  INSERT_MULTI_ROUTES,
} from '../actions';

export const setRouting = (data) => ({
  type: SET_ROUTING,
  payload: data,
});

export const updateSolicitation = (solicitation) => ({
  type: UPDATE_SOLICITATION,
  payload: solicitation,
});

export const toggleRouteSelected = (routeId) => ({
  type: TOGGLE_ROUTE_SELECTED,
  payload: routeId,
});

export const selectAllRoutes = (routeStatus) => ({
  type: SELECT_ALL_ROUTES,
  payload: routeStatus,
});

export const updateRoute = (route) => ({
  type: UPDATE_ROUTE,
  payload: route,
});

export const updateWaypoint = (waypoint) => ({
  type: UPDATE_WAYPOINT,
  payload: waypoint,
});

export const finishRoute = (route) => ({
  type: FINISH_ROUTE,
  payload: route,
});

export const insertRoute = (route) => ({
  type: INSERT_ROUTE,
  payload: route,
});

export const insertMultiRoutes = (routes) => ({
  type: INSERT_MULTI_ROUTES,
  payload: routes,
});
