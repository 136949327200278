/* eslint-disable import/no-cycle */
import api from './index';

const prefix = 'vehicle';
const getVehicleList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );
const getVehicleDetails = (id) => api.get(`${prefix}/${id}`);
const postVehicle = (data) => api.post(prefix, data);
const putVehicle = (id, data) => api.put(`${prefix}/${id}`, data);
const deleteVehicle = (id) => api.delete(`${prefix}/${id}`);
const postVehiclePhoto = (id, data) =>
  api.post(`${prefix}/${id}/photo`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export {
  getVehicleList,
  getVehicleDetails,
  postVehicle,
  putVehicle,
  deleteVehicle,
  postVehiclePhoto,
};
