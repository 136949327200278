import {
  postLogin,
  postForgot,
  getProfile,
  putAuthOTPValidate,
  putAuthResetPassword,
} from '../api';

const login = (username, password) =>
  new Promise((resolve, reject) => {
    postLogin({ username, password })
      .then((data) => {
        localStorage.setItem('token', JSON.stringify(data));
        getProfile()
          .then(({ data }) => {
            localStorage.setItem('auth', JSON.stringify(data));
            if (data.last_password_change === null) {
              window.location.href = '/auth/reset-password';
            }
            resolve(data);
          })
          .catch(reject);
      })
      .catch(reject);
  });

const forgotPassword = (params) =>
  new Promise((resolve, reject) => {
    postForgot(params)
      .then((data) => {
        resolve(data);
      })
      .catch(reject);
  });

const validateOTP = (params) =>
  new Promise((resolve, reject) => {
    putAuthOTPValidate(params)
      .then((data) => {
        resolve(data);
      })
      .catch(reject);
  });

const resetPassword = (params) =>
  new Promise((resolve, reject) => {
    putAuthResetPassword(params)
      .then((data) => {
        resolve(data);
      })
      .catch(reject);
  });

export const authService = {
  login,
  forgotPassword,
  validateOTP,
  resetPassword,
};
