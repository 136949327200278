import React, { useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';

export default function RoutingWaypoint({ waypoint }) {
  const [collapse, setCollapse] = useState(false);

  let arrivedAt = waypoint.arrived_at
    ? moment(waypoint.arrived_at).format(' DD/MM HH:mm')
    : null;

  return (
    <div className="mt-2">
      <div
        style={{ fontSize: '11px' }}
        className={classNames(
          'p-2',
          'rounded-simple',
          'shadow-sm',
          'bg-theme-light-op',
          {
            // "border-status border-warning": arrivedAt === null,
            'border-status border-success bg-success-light': arrivedAt !== null,
          },
        )}
        onClick={() => setCollapse(!collapse)}
      >
        <div className="float-right text-right">
          <small>
            <strong>#{waypoint.id}</strong>
          </small>
          <br />
        </div>
        <div className="passenger-detail">
          <i className="simple-icon-rocket text-warning" /> D/:{' '}
          {waypoint.address}
        </div>
        <div className="passenger-detail">
          <strong>
            <i className="simple-icon-calendar" /> Previsão:{' '}
            {moment(waypoint.arrival_estimate).format('HH:mm')}
          </strong>
        </div>
        {collapse && (
          <div className="passenger-detail mt-2">
            <i className="simple-icon-location-pin" /> Chegou?{' '}
            {arrivedAt === null ? 'Não' : 'Sim'}
            {arrivedAt && (
              <>
                <br />
                <br />
                <i className="simple-icon-direction text-info" /> No local:
                {arrivedAt} - {waypoint.arrived_lat} / {waypoint.arrived_lng}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
