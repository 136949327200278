/* eslint-disable no-param-reassign */
/* eslint-disable prettier/prettier */
/* eslint-disable import/no-cycle */
import axios from 'axios';

// eslint-disable-next-line import/no-extraneous-dependencies
const uuidv1 = require('uuid/v1');

function getToken() {
  let token = null;
  try {
    token = JSON.parse(localStorage.getItem('token')).access_token;
  } catch (e) {
    localStorage.removeItem('token');
  }
  return token;
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  crossdomain: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    if (typeof config.headers.Authorization === 'undefined') {
      const token = getToken();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    config.headers['X-Transaction'] = uuidv1();

    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (
      typeof error.response !== 'undefined' &&
      error.response.status === 401
    ) {
      localStorage.removeItem('token');
      if (window.location.pathname !== '/auth/login') {
        window.location.href = '/auth/login';
      }
    }
    return Promise.reject(error);
  },
);

export default instance;

export * from './auth';
export * from './person';
export * from './utility';
export * from './contract';
export * from './user';
export * from './role';
export * from './solicitation';
export * from './profile';
export * from './address';
export * from './vehicle';
export * from './driver';
export * from './routing';
export * from './export';
export * from './dashboard';
export * from './charter';
export * from './nonWorkingDay';
export * from './costCenter';
export * from './serviceOrder';
