import { checkCanNext } from '../../components/routing/RoutingRoute';
import {
  SET_ROUTING,
  UPDATE_SOLICITATION,
  TOGGLE_ROUTE_SELECTED,
  SELECT_ALL_ROUTES,
  UPDATE_ROUTE,
  UPDATE_WAYPOINT,
  FINISH_ROUTE,
  INSERT_ROUTE,
  INSERT_MULTI_ROUTES,
} from '../actions';

const INIT_STATE = {
  routes: [],
  solicitations: [],
};

export default (state = INIT_STATE, action) => {
  var rouIndex = null;
  switch (action.type) {
    // SET_ROUTING
    case SET_ROUTING:
      return { ...state, ...action.payload };

    // UPDATE_SOLICITATION
    case UPDATE_SOLICITATION:
      const solIndex = state.solicitations.findIndex(
        (obj) => obj.id === action.payload.id,
      );

      if (solIndex > -1) {
        state.solicitations[solIndex] = action.payload;
      } else {
        state.solicitations.push(action.payload);
      }
      return { ...state };

    // TOGGLE_ROUTE_SELECTED
    case TOGGLE_ROUTE_SELECTED:
      rouIndex = state.routes.findIndex((obj) => obj.id === action.payload);

      if (rouIndex > -1) {
        state.routes[rouIndex].isSelected = !state.routes[rouIndex].isSelected;
      }
      return { ...state };

    // SELECT_ALL_ROUTES
    case SELECT_ALL_ROUTES:
      const newRoutes = state.routes.map((r) => ({
        ...r,
        isSelected: r.status === action.payload && checkCanNext(r),
      }));
      return { ...state, routes: newRoutes };

    // UPDATE_ROUTE
    case UPDATE_ROUTE:
      rouIndex = state.routes.findIndex((obj) => obj.id === action.payload.id);

      if (rouIndex > -1) {
        state.routes[rouIndex] = action.payload;
      } else {
        state.routes.push(action.payload);
      }

      return { ...state };

    // UPDATE_WAYPOINT
    case UPDATE_WAYPOINT:
      rouIndex = state.routes.findIndex(
        (obj) => obj.id === action.payload.routing_id,
      );

      if (rouIndex > -1) {
        let wayIndex = state.routes[rouIndex].waypoints.findIndex(
          (obj) => obj.id === action.payload.id,
        );

        if (wayIndex > -1) {
          state.routes[rouIndex].waypoints[wayIndex] = action.payload;
        }
      }

      return { ...state };

    // FINISH_ROUTE
    case FINISH_ROUTE:
      rouIndex = state.routes.findIndex((obj) => obj.id === action.payload.id);

      if (rouIndex > -1) {
        state.routes.splice(rouIndex, 1);
      }

      return { ...state };

    // INSERT_ROUTE
    case INSERT_ROUTE:
      state.routes.push(action.payload);

      return { ...state };

    // INSERT_MULTI_ROUTES
    case INSERT_MULTI_ROUTES:
      action.payload.forEach((route) => {
        state.routes.push(route);
      });

      return { ...state };

    // DEFAULT
    default:
      return { ...state };
  }
};
