/* eslint-disable import/no-cycle */
import api from './index';

const prefix = 'person';
const getPersonList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );
const getPersonDetails = (id) => api.get(`${prefix}/${id}`);
const postPerson = (data) => api.post(prefix, data);
const putPerson = (id, data) => api.put(`${prefix}/${id}`, data);
const deletePerson = (id) => api.delete(`${prefix}/${id}`);
const getPersonValidateTaxId = (taxId) =>
  api.get(`${prefix}/tax-id/validate?tax_id=${taxId}`);
const getPersonValidateId = (id) => api.get(`${prefix}/id/validate?id=${id}`);
const postPersonPhoto = (id, data) =>
  api.post(`${prefix}/${id}/photo`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
const postPersonBatch = (data) =>
  api.post(`${prefix}/batch`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
const getPersonAddressList = (id, query) =>
  api.get(
    `${prefix}/${id}/address?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );

export {
  getPersonList,
  getPersonDetails,
  postPerson,
  putPerson,
  deletePerson,
  getPersonValidateTaxId,
  getPersonValidateId,
  postPersonPhoto,
  getPersonAddressList,
  postPersonBatch,
};
