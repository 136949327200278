/* eslint-disable import/no-cycle */
import api from './index';

const prefix = 'role';
const getRoleList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );
const getRoleDetails = (id) => api.get(`${prefix}/${id}`);

export { getRoleList, getRoleDetails };
