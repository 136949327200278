import React, { useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useDrag } from 'react-dnd';
import IntlMessages from '../../helpers/IntlMessages';
import Status from '../common/Status';
import MaskedInput from 'react-text-mask';

export default function RoutingPassenger({
  passenger,
  showBg,
  onChangePosition,
}) {
  const [collapse, setCollapse] = useState(false);

  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: 'SOLICITATION',
    item: passenger,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  let scheduledAt = moment(passenger.scheduled_at).format('DD/MM/YYYY');
  let curDate = moment().format('DD/MM/YYYY');

  if (!showBg) {
    curDate = '-';
  }

  return (
    <div className="mt-2" ref={dragRef}>
      <div
        style={{ fontSize: '11px' }}
        className={classNames(
          'p-2',
          'rounded-simple',
          'shadow-sm',
          'border-ddd',
          {
            'alert-info text-dark': scheduledAt === curDate,
            'bg-theme-light-op': scheduledAt !== curDate,
            'border-status border-danger canceled':
              passenger.status === 'CANCELED',
            'border-status border-warning':
              passenger.started_at !== null && passenger.ended_at === null,
            'border-status border-success':
              passenger.started_at !== null && passenger.ended_at !== null,
            'bg-secondary': isDragging,
            'bg-theme-4-20': passenger.isModified,
          },
        )}
        onClick={() => setCollapse(!collapse)}
      >
        <div className="float-right text-right">
          <small>
            <strong>
              #{passenger.id}{' '}
              <MaskedInput
                mask={[/\d/]}
                defaultValue={passenger.position}
                disabled={
                  passenger.status === 'RUNNING' ||
                  passenger.status === 'CANCELED' ||
                  passenger.routing_id === null
                }
                className="dnd-position-input"
                onChange={(e) => {
                  onChangePosition(passenger, e.target.value);
                }}
              />
            </strong>
          </small>
          <br />
        </div>
        <div className="passenger-detail">
          <i className="simple-icon-speedometer" />{' '}
          <IntlMessages id={passenger.vehicle_type} />
        </div>
        <div className="passenger-detail">
          <i className="simple-icon-rocket text-warning" /> D/:{' '}
          {passenger.loading.name} {passenger.loading.city.name} |{' '}
          {passenger.loading.neighborhood}
        </div>
        {collapse && (
          <div className="passenger-detail mb-3">
            <i className="simple-icon-location-pin" />{' '}
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${passenger.loading.address}, ${passenger.loading.number} - ${passenger.loading.city.name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {passenger.loading.address}, {passenger.loading.number} -{' '}
              {passenger.loading.complement} - {passenger.loading.city.name}
            </a>
          </div>
        )}
        <div className="passenger-detail">
          <i className="simple-icon-flag text-success" /> P/:{' '}
          {passenger.unloading.name} {passenger.unloading.city.name} |{' '}
          {passenger.unloading.neighborhood}
        </div>
        {collapse && (
          <div className="passenger-detail mb-3">
            <i className="simple-icon-location-pin" />{' '}
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${passenger.unloading.address}, ${passenger.unloading.number} - ${passenger.unloading.city.name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {passenger.unloading.address}, {passenger.unloading.number} -{' '}
              {passenger.unloading.complement} - {passenger.unloading.city.name}
            </a>
          </div>
        )}
        <div className="passenger-detail">
          <strong>
            <i className="simple-icon-calendar" />{' '}
            {moment(passenger.scheduled_at).format('DD/MM HH:mm')}
          </strong>
        </div>
        <div className="passenger-detail">
          <i className="simple-icon-briefcase text-dark" />{' '}
          {passenger.contract.customer.name}
        </div>

        {passenger.notes && passenger.notes !== '-' && (
          <div className="passenger-detail">
            <i className="simple-icon-exclamation" /> <i>{passenger.notes}</i>
          </div>
        )}
        {collapse && (
          <div className="passenger-detail mt-2">
            <i className="simple-icon-user" /> {passenger.person_name}
            {passenger.waiting_at && (
              <>
                <br />
                <br />
                <i className="simple-icon-direction text-info" /> No local:
                {moment(passenger.waiting_at).format(' DD/MM HH:mm')}
              </>
            )}
            {passenger.started_at && (
              <>
                <br />
                <i className="simple-icon-rocket text-warning" /> Embarcou:
                {moment(passenger.started_at).format(' DD/MM HH:mm')}
              </>
            )}
            {passenger.started_at && passenger.waiting_at && (
              <>
                <br />
                <i className="simple-icon-clock" /> Esperou:{' '}
                {moment
                  .duration(
                    moment(passenger.started_at).diff(
                      moment(passenger.waiting_at),
                    ),
                  )
                  .asMinutes()}{' '}
                Minutos
              </>
            )}
            {passenger.ended_at && (
              <>
                <br />
                <i className="simple-icon-flag text-success" /> Desembarcou:
                {moment(passenger.ended_at).format(' DD/MM HH:mm')}
              </>
            )}
            {passenger.status === 'CANCELED' && (
              <>
                <br />
                <Status value={passenger.status} />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
