import {
  getSolicitationList,
  getSolicitationDetails,
  putSolicitation,
  postSolicitationCancel,
  postSolicitation,
  postSolicitationBatch,
  deleteSolicitation,
  postSolicitationExport,
  postSolicitationImport,
  getSolicitationPhotos,
} from '../api';

const list = (params) => getSolicitationList(params);
const show = (id) => getSolicitationDetails(id);
const update = (id, data) => putSolicitation(id, data);
const store = (data) => postSolicitation(data);
const destroy = (id) => deleteSolicitation(id);
const batch = (data) => postSolicitationBatch(data);
const cancel = (ids, reason) => postSolicitationCancel({ ids, reason });
const exportSolicitations = (data) => postSolicitationExport(data);
const importSolicitations = (data) => postSolicitationImport(data);
const photos = (id) => getSolicitationPhotos(id);

export const solicitationService = {
  list,
  show,
  update,
  store,
  batch,
  destroy,
  cancel,
  exportSolicitations,
  importSolicitations,
  photos,
};
