/* eslint-disable import/no-cycle */
import api from './index';

const prefix = 'charter';
const getCharterList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );
const getCharterDetails = (id) => api.get(`${prefix}/${id}`);
const postCharter = (data) => api.post(prefix, data);
const putCharter = (id, data) => api.put(`${prefix}/${id}`, data);
const deleteCharter = (id) => api.delete(`${prefix}/${id}`);

const putCharterDirections = (id, data) =>
  api.put(`${prefix}/${id}/directions`, data);
const getCharterDirections = (id) => api.get(`${prefix}/${id}/directions`);

const putCharterPassengers = (id, data) =>
  api.put(`${prefix}/${id}/passengers`, data);
const getCharterPassengers = (id) => api.get(`${prefix}/${id}/passengers`);
const getCharterWaypoints = (id) => api.get(`${prefix}/${id}/waypoints`);

export {
  getCharterList,
  getCharterDetails,
  postCharter,
  putCharter,
  deleteCharter,
  putCharterDirections,
  getCharterDirections,
  putCharterPassengers,
  getCharterPassengers,
  getCharterWaypoints,
};
