import {
  getCharterList,
  getCharterDetails,
  putCharter,
  postCharter,
  deleteCharter,
  putCharterDirections,
  getCharterDirections,
  getCharterPassengers,
  putCharterPassengers,
  getCharterWaypoints,
} from '../api';

const list = (params) => getCharterList(params);
const show = (id) => getCharterDetails(id);
const update = (id, data) => putCharter(id, data);
const store = (data) => postCharter(data);
const destroy = (id) => deleteCharter(id);
const updateDirections = (id, data) => putCharterDirections(id, data);
const directions = (id) => getCharterDirections(id);
const updatePassengers = (id, data) => putCharterPassengers(id, data);
const passengers = (id) => getCharterPassengers(id);
const waypoints = (id) => getCharterWaypoints(id);

export const charterService = {
  list,
  show,
  update,
  store,
  destroy,
  updateDirections,
  directions,
  updatePassengers,
  passengers,
  waypoints,
};
