import {
  getDriverList,
  getDriverDetails,
  putDriver,
  postDriver,
  deleteDriver,
  postDriverUser,
  getDriverStatus,
} from '../api';

const list = (params) => getDriverList(params);
const show = (id) => getDriverDetails(id);
const update = (id, data) => putDriver(id, data);
const store = (data) => postDriver(data);
const destroy = (id) => deleteDriver(id);
const generateUser = (id) => postDriverUser(id);
const status = (params) => getDriverStatus(params);

export const driverService = {
  list,
  show,
  update,
  store,
  destroy,
  generateUser,
  status,
};
