import {
  getNonWorkingDayList,
  getNonWorkingDayDetails,
  putNonWorkingDay,
  postNonWorkingDay,
  deleteNonWorkingDay,
  putNonWorkingDayCharters,
} from '../api';

const list = (params) => getNonWorkingDayList(params);
const show = (id) => getNonWorkingDayDetails(id);
const update = (id, data) => putNonWorkingDay(id, data);
const store = (data) => postNonWorkingDay(data);
const destroy = (id) => deleteNonWorkingDay(id);
const charters = (id, data) => putNonWorkingDayCharters(id, data);

export const nwdService = {
  list,
  show,
  update,
  store,
  destroy,
  charters,
};
