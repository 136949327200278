/* eslint-disable import/no-cycle */
import api from './index';

const prefix = 'non-working-day';
const getNonWorkingDayList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );
const getNonWorkingDayDetails = (id) => api.get(`${prefix}/${id}`);
const postNonWorkingDay = (data) => api.post(prefix, data);
const putNonWorkingDay = (id, data) => api.put(`${prefix}/${id}`, data);
const deleteNonWorkingDay = (id) => api.delete(`${prefix}/${id}`);
const putNonWorkingDayCharters = (id, data) =>
  api.put(`${prefix}/${id}/charters`, data);

export {
  getNonWorkingDayList,
  getNonWorkingDayDetails,
  postNonWorkingDay,
  putNonWorkingDay,
  deleteNonWorkingDay,
  putNonWorkingDayCharters,
};
