import axios from 'axios';
// import api from "./index";

const apiNoAuth = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  crossdomain: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const prefix = 'utility';
const getUtilityZipcodeSearch = (zipcode) =>
  apiNoAuth.get(`${prefix}/zipcode/search?zipcode=${zipcode}`);

const getStates = () => apiNoAuth.get(`${prefix}/state`);

const getCities = (stateId) =>
  apiNoAuth.get(`${prefix}/city?state_id=${stateId}`);

export { getUtilityZipcodeSearch, getStates, getCities };
